.container {
    width: 82%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 60px 0px;
    align-items: center;
    justify-content: center;
}

.container h2 {
    font-family: Quicksand;
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #312584;
}

.title {
    color: #393939;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 0px;
    width: 76%;
    margin: auto;
}

.boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 80px;
    margin-top: 100px;
}

.box {

    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    padding: 30px;
    border-radius: 20px;
}

.box svg {
    width: 100px;
    margin-top: -70px;
    height: fit-content;
}

.subtitle {
    font-family: Quicksand;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    color: black;
    padding-top: 10px;
}

.text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #393939;
}

@media (max-width: 1130px) {
    .container {
        width: 95%;
        padding: 45px 0px;
    }

    .container h2 {
        font-size: 36px;
        line-height: normal;
    }

    .title {
        font-size: 20px;
        line-height: normal;
        padding-bottom: 30px;
    }

    .box svg {
        width: 90px;
    }

    .subtitle {
        font-size: 26px;
    }

    .text {
        line-height: normal;
        font-size: 16px;
    }

    .boxes {
        gap: 2%;
    }

    .box {
        width: 82%;
        padding: 15px;
        margin-bottom: 80px;
    }
}

@media (max-width: 768px) {
    .boxes {
        flex-direction: column;
        margin-top: 30px;
    }

    .box {
        width: 85%;
        padding: 20px;
        margin-top: 55px;
        gap: 5px;
        margin-bottom: 40px;
    }

    .container h2 {
        text-align: center;
        font-size: 26px;
    }

    .title {
        font-size: 14px;
        padding-bottom: 1px;
        margin-bottom: -60px;
    }

    .subtitle {
        font-size: 19px;
    }

    .text {
        font-size: 12px;
        text-align: justify;
    }
}

@media (min-width: 1279px) and (max-width: 1440px) {

    .container h2 {
        font-size: 50px;
        line-height: 62px;
    }
}